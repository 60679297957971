.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #d50032;
    border: 1px solid #d50032;
}

.DateInput {
    width: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    // height: calc(2rem + 5px);
    // padding: 0 8px;
    // font-size: 1rem;
    // line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
}

.DateInput_input {
    font-size: inherit;
    padding: 0;
    line-height: 1rem;
}

.DateInput_input__focused {
    border-bottom: 2px solid #d50032;
}

.DateRangePickerInput {
    display: flex;
    min-width: 16rem;
    align-items: flex-end;
}

.DateRangePickerInput .DateInput {
    height: unset;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #d50032;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #d50032;
}

.is-invalid .SingleDatePickerInput {
    border-color: #dc3545;
}

.is-invalid .invalid-feedback {
    display: block;
}

.SingleDatePicker {
    width: 100%;
    height: 100%;
}

.SingleDatePicker_picker {
    z-index: 3 !important;
}

.SingleDatePickerInput {
    width: 100%;
    height: 50%;
}

.SingleDatePickerInput__withBorder {
    border: none;
}

.bootstrap-timepicker .form-control.time-picker-input {
    color: #495057;
    text-align: left;
    border-color: #ced4da;
    background-color: #fff;
    // font-size: 24px;
}

.DayPicker--circle {
    .CalendarDay.CalendarDay_1.CalendarDay__default.CalendarDay__default_2 {
        border: none;
        border-radius: 50%;
    }
}

.highlight-date {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: #A2A2A2;
    width: 100%;
    height: 100%;
}

.highlight-date.highlight-date--current {
    background: #d50032;
}
