.logo-div{
  height: calc(100vh - 7.5rem);
}

.all-screen{
  height: 100vh;
}

.login-logo{
  max-width: 200px;
  align-self: center;
}

.home-with-logo{
  max-width: -webkit-fill-available;
}

.label-title{
  font-weight: bold;
  padding-left: .15rem;
  text-decoration: underline;
}

.label-title-warning{
  color: #D50032;
  font-weight: bold;
  padding-left: .15rem;
  text-decoration: underline;
}

.label-field{
  background: whitesmoke;
  padding:  0.3rem 0.5rem 0.3rem 0.5rem;
  max-width: 525px;
}

.wmata-buttons-text{
  font-size: medium;
}

.change-password{
  color: #D50032;
  cursor: pointer;
}

.change-password:hover{
  text-decoration: underline;
}

.swal2-container {
  z-index: 115000 !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 110000 !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0,.4) !important;
}

.clickable {
  cursor: pointer !important;
}